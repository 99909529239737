import * as React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/Seo";
import Quotes from "../components/Quotes";

const BookNowTemplate = ({ title, quotes, content, cardBlock }) => {
  return (
    <div className="section">
      <div className="w-full max-w-screen-xl px-6 mx-auto">
        <div className={`text-center md:text-left pt-8 pb-12 md:py-16`}>
          <h1 className="lg:pr-12">{title}</h1>
        </div>
      </div>
      <div
        className={`mx-auto max-w-screen-xl w-full px-6`}
      >
      <div className={`md:mb-8 grid md:grid-cols-2 gap-8 text-center md:h-[400px] book-now`}>
        <div className={`border border-green py-14 px-6 md:p-8 flex justify-center items-center text-center`}>
        <div
            className={`book-now__content tc-h2`}
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          />
        </div>
        <div className={`border tc-h2 border-green py-14 md:p-8 flex-col flex justify-center items-center text-center`}>
            <div className={`mb-6 px-6`}>
                {cardBlock.title}
            </div>
            <a
                href={cardBlock.link}
                target="_blank"
                rel="noreferrer nofollow noopener"
                className={`py-2.5 px-8 font-medium text-sm rounded-[10px] bg-green text-white transition `}
              >
                {cardBlock.linkText}
              </a>
        </div>
      </div>
      </div>
      <Quotes quotes={quotes} />
    </div>
  );
};

BookNowTemplate.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const BookNowPage = ({ data }) => {
  const frontmatter = data.markdownRemark.frontmatter;

  return (
    <Layout>
       <Seo title={frontmatter.title} description={data.markdownRemark.html} />

      <BookNowTemplate
        title={frontmatter.title}
        quotes={frontmatter.quotes}
        content={data.markdownRemark.html}
        cardBlock={frontmatter.cardBlock}
      />
    </Layout>
  );
};

BookNowPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export const BookNowPageQuery = graphql`
  query BookNowPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        quotes {
            quote
            author
        }
        cardBlock {
            title
            linkText
            link
        }
      }
    }
  }
`;
export default BookNowPage;